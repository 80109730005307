import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'

// Components
import DilanButton from '../../atoms/DilanButton'
import MobileMenu from '../../molecules/MobileMenu'
import UserDropdown from '../../molecules/UserDropdown'
import LanguageDropdown from '../../molecules/LanguageDropdown'

// Context
import UserContext from '../../../context/User/User.context'
import LocalizationContext from '../../../context/Localization/Localization.context'

//Utils
import { getFont } from '../../../utils/utils'

// Constants
import { header } from '../../../config/localization'
import { USER_TOKEN } from '../../../config/constants/localStorage'
import { LOGIN_REDIRECT } from '../../../config/constants/sessionStorage'

// Services
import { validateSession } from '../../../services/auth'

// Styles
import {
  ptCorrection,
  pbCorrection,
  headerNav,
  headerLogo,
  headerLogIn,
  headerWrapper,
  headerOptions,
  headerContainer,
  headerMobileMenu,
  headerMobileStripes,
} from './styles.module.scss'

const Header: React.FC = () => {
  const { updateUser } = useContext(UserContext)
  const { language } = useContext(LocalizationContext)

  const [localization, setLocalization] = useState(header.Default)
  const [mbMenu, setMbMenu] = useState(false)

  const getUser = async (token) => {
    const user = await validateSession(token)
    const contextUser = {
      ...user,
      lastChapter: String(user.lastChapter),
      tier: user.tier ? String(user.tier) : undefined,
    }
    updateUser(contextUser)
  }

  useEffect(() => {
    const newLocalization = header[language]
    if (typeof newLocalization !== 'undefined') {
      setLocalization(newLocalization)
    }
    const token = localStorage.getItem(USER_TOKEN)
    if (token) {
      getUser(token)
    }
  }, [language])

  const loginButton = () => {
    if (typeof window !== 'undefined' && localStorage.getItem(USER_TOKEN)) {
      return (
        <div className="only-desktop">
          <UserDropdown
            keepReading={localization.keepReading}
            myAccount={localization.myAccount}
            logoutText={localization.logOut}
          />
        </div>
      )
    }
    return (
      <DilanButton
        type="secondary"
        handleClick={() => {
          sessionStorage.setItem(LOGIN_REDIRECT, window.location.href)
          navigate('/login')
        }}
        className={`${headerLogIn} ${getFont(
          'font-lato',
          ptCorrection
        )} only-desktop`}
      >
        {localization.logIn}
      </DilanButton>
    )
  }

  return (
    <>
      {mbMenu && (
        <MobileMenu localization={localization} setMbMenu={setMbMenu} />
      )}
      <div className={`${headerWrapper} block-wrapper`}>
        <div className={`${headerContainer} container`}>
          <div
            className={`only-mobile-tb ${headerMobileMenu}`}
            onClick={() => {
              setMbMenu(true)
            }}
          >
            <hr className={headerMobileStripes} />
            <hr className={headerMobileStripes} />
          </div>
          <img
            className={`${headerLogo}`}
            src="https://dilancovak.com/images/logosolo_full.svg"
            alt="Dilan Covak logo"
            onClick={() => navigate('/')}
          />
          <div className={headerOptions}>
            {localization.comic && (
              <div
                className={`${headerNav} ${getFont(
                  'font-lato',
                  pbCorrection
                )} only-desktop`}
                onClick={() => {
                  navigate('/comic')
                }}
              >
                {localization.comic}
              </div>
            )}
            {localization.world && (
              <div
                className={`${headerNav} ${getFont(
                  'font-lato',
                  pbCorrection
                )} only-desktop`}
                onClick={() => {
                  navigate('/world/story')
                }}
              >
                {localization.world}
              </div>
            )}
            {localization.aboutUs && (
              <div
                className={`${headerNav} ${getFont(
                  'font-lato',
                  pbCorrection
                )} only-desktop`}
                onClick={() => {
                  navigate('/about')
                }}
              >
                {localization.aboutUs}
              </div>
            )}
            {localization.logIn && loginButton()}
            <LanguageDropdown />
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
