import React, { useContext, useEffect } from 'react'
import AdSense from 'react-adsense'

// Components
import Footer from '../../organisms/Footer'
import Header from '../../organisms/Header'

// Constants
import { LOCALIZATION } from '../../../config/constants/localStorage'

// GrpahQL
import { getGraphLang } from '../../../graphQL'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Styles
import { mainContainer, adWrapper, adContainer } from './styles.module.scss'

// Types
import { ValidLanguage } from '../../../types'

interface Props {
  children: React.ReactNode
}

const PageTemplate: React.FC<Props> = ({ children, ...attr }) => {
  const activeLanguages = getGraphLang()

  const { language, updateLanguage } = useContext(LocalizationContext)

  useEffect(() => {
    const lang = localStorage.getItem(LOCALIZATION)
    /* Case LOCALIZTION doesn't exist */
    if (typeof lang === 'undefined' || lang === null) {
      localStorage.setItem(LOCALIZATION, 'ENG')
    } else {
      /* Case LOCALIZATION is not valid */
      const activeLang = activeLanguages?.filter(
        (language) => language.code === lang
      )
      if (activeLang?.length === 0) {
        localStorage.setItem(LOCALIZATION, 'ENG')
      } else if (language !== lang) {
        updateLanguage(lang as ValidLanguage)
      }
    }
  }, [activeLanguages])

  return (
    <div className={mainContainer}>
      <Header />
      <div className={`${adWrapper} block-wrapper`}>
        <div className={`${adContainer} container`}>
          <AdSense.Google
            client="ca-pub-3473260525556751"
            slot="6744697784"
            style={{ display: 'block' }}
            format="auto"
            responsive="true"
          />
        </div>
      </div>
      {children}
      <Footer />
    </div>
  )
}

export default PageTemplate
